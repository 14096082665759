import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApibundleService } from '../../services/apibundle.service';
import { LicenseDetail } from '../../interfaces/licenseDetail.model';
import { FormControl, Validators } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { CommonService } from 'src/app/services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-license-creation',
  templateUrl: './license-creation.component.html',
  styleUrls: ['./license-creation.component.css']
})
export class LicenseCreationComponent implements OnInit {

  err: any;
  environmentList: any = [];
  licenseDetail = new LicenseDetail();
  envName = new FormControl('', [Validators.required]);
  appName = new FormControl('', [Validators.required]);
  noOfLicense = new FormControl('', [Validators.required]);
  bussUnit = new FormControl('', [Validators.required]);
  envOwner = new FormControl('', [Validators.required]);
  isAppEnable: boolean = true;
  applicationList: any = [];
  selectedAppItems: any = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredApps: any =[];
  filteredEnvironment: any =[];  
  @ViewChild('adminInput', {static: false}) envNameInput;
  @ViewChild('connectInput', {static: false}) appNameInput;
  isSubmitted: boolean = false;
  businessUnits: any = [];
  spinner: boolean = false;
  spinnerName = "licenseCreate";

  constructor(private apibundleService: ApibundleService, private router: Router,
    private authService: MsalService, private commonService: CommonService,
    public location: Location, private ngxSpinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getEnvironments();
  }

   /*--------  FUNCTION TO GET THE ENVIRONMENTS -------- */
   async getEnvironments() {
    this.apibundleService.showSpinner('licenseCreate'); 
    this.spinner = true; 
    this.apibundleService.getLicenseEnvironmentList().subscribe(
      res => {
        this.environmentList = res;
        this.filteredEnvironment = res;
        this.apibundleService.hideSpinner('licenseCreate');  
        this.spinner = false;
      },
      err => {
        this.err = err;
        console.log(err);
        this.apibundleService.hideSpinner('licenseCreate');  
        this.spinner = false;
      });
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO GET ALL POWER APPS BY ENVIRONMENT NAME-------- */
  getPowerAppByEnvName(event) {
    this.isAppEnable = true;
    this.selectedAppItems = [];
    var busUnitAbbr = this.getBusUnitAbbrFromEnvName(event.option.value);
    this.apibundleService.getPowerAppByEnvName(event.option.value).subscribe(
      res => {
        this.applicationList = res;
        this.filteredApps = res;
        if (this.applicationList && this.applicationList != null && this.applicationList.length > 0) {
            this.isAppEnable = false;
        } else {
          this.selectedAppItems.push("NA");
          this.isAppEnable = true;
        }
        if(busUnitAbbr && busUnitAbbr.length > 0)
          this.getBussUnitByAbbr(busUnitAbbr);
        else 
          this.getBusinessUnits();
        this.getEnvOwnerByEnvName(event.option.value);
      },
      err => {
        this.err = err;
        console.log(err);
      });
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO GET BUSINESS UNIT FROM BUSINESS UNIT ABBRIVIATION-------- */
  getBussUnitByAbbr(busUnitAbbr: any) {
    this.apibundleService.getBussUnitByAbbr(busUnitAbbr).subscribe(
      res => {
        this.businessUnits = [];
        this.businessUnits.push(res);
        this.licenseDetail.BusinessUnit = res.businessUnitName;
      },
      err => {
        this.err = err;
        console.log(err);
      });
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO GET BUSINESS UNIT ABBRIVIATION FROM ENVIRONMENT NAME-------- */
  getBusUnitAbbrFromEnvName(envName) {
    var busUnitAbbr = envName.substring(
      envName.indexOf("-"), 
      envName.lastIndexOf("-")
    );
    busUnitAbbr = busUnitAbbr.substring(
      busUnitAbbr.indexOf("-") + 1, 
      busUnitAbbr.lastIndexOf("-")
    );
   return busUnitAbbr;
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO GET ENVIRONMENT OWNER BY ENVIRONEMNT NAME-------- */
  getEnvOwnerByEnvName(envName) {
    let index = -1;
    index = this.environmentList.findIndex(environment => environment.environmentName === envName);
      if(index > -1) {      
        this.licenseDetail.EnvironmentOwner = this.environmentList[index].admin;
      }
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO ADD SELECTED APPLICATION -------- */
  addSelectedApp(event) {
    let appName = event.option.value;
    if (appName) {
    let index = -1;
    index = this.selectedAppItems.findIndex(value => value === appName);
      if(index < 0) {      
        this.selectedAppItems.push(appName); 
      }
    } 
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO REMOVE SELECTED APPLICATION -------- */
  removeApp(appName) {
    let i = -1;
    i = this.selectedAppItems.findIndex(value => value === appName );
    if(i > -1)
      this.selectedAppItems.splice(i, 1);
  }
  /*--------  END OF FUNCTION -------- */

   /*--------  FUNCTION TO FILTER CONNECTORS -------- */
   filterApps(event) {
    let filterValue = event.target.value.toLowerCase();
      this.filteredApps = this.applicationList.filter(app => app.applicationName.toLowerCase().includes(filterValue));
  }
  /*--------  END OF FUNCTION -------- */

   /*--------  FUNCTION TO FILTER ENVIRONMENT NAMES -------- */
   filterEnv(event) {
    let filterValue = event.target.value.toLowerCase();
      this.filteredEnvironment = this.environmentList.filter(app => app.environmentName.toLowerCase().includes(filterValue));
  }
  /*--------  END OF FUNCTION -------- */

 /*--------  FUNCTION IS CALLED WHEN THE FORM IS SUBMITTED. CHECK VALIDATION. -- */
 async processForm(formData) {
  if (formData.submitted)
      this.isSubmitted = true;
    this.licenseDetail.ApplicationName = this.selectedAppItems;
    this.licenseDetail.RequestStatus = "New";
    this.licenseDetail.UserName = this.authService.getAccount().userName;
    this.licenseDetail.PartitionKey = this.licenseDetail.EnvironmentName;
    if(this.validate() && this.selectedAppItems.length > 0)
      this.createLicenseRequest(this.licenseDetail);
  } 

/*--------  END OF FUNCTION -------- */

/*--------  FUNCTION TO CHECK ANY EMMPTY VALUES. RETURN BOOLEAN -------- */
  validate() {
    for (var key in this.licenseDetail) {
      if (this.licenseDetail[key] == null || this.licenseDetail[key] == '' || this.licenseDetail[key].length == 0) {
        return false;
      }
    }
    return true;
  }
  /*--------  END OF FUNCTION -------- */

/*--------  FUNTION TO CREATE REQUEST FOR NEW ENVIRONMENT -------- */
async createLicenseRequest(licenseDetail) {
  this.ngxSpinner.show();
  this.apibundleService.createLicenseRequest(licenseDetail).subscribe(
    res => {
      this.ngxSpinner.hide();
      this.commonService.openSnackBar("License request has been created successfully", "OK");
      this.router.navigate(['/services']);
    },
    err => {
      this.err = err;
      console.log(err);
      this.ngxSpinner.hide();
    });
}
/*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION WILL RETUN COMMON ERROR MESSAGE -------- */
  getErrorMessage() {
    return 'You must enter a value';
  }
  /*--------  END OF FUNCTION -------- */

   /*--------  FUNCTION TO GET THE BUSINESS UNITS -------- */
   async getBusinessUnits() {
    this.apibundleService.getBusinessUnits().subscribe(
      res => {
        this.businessUnits = res;
      },
      err => {
        this.err = err;
        console.log(err);
      });
  }
  /*--------  END OF FUNCTION -------- */

  isNumberKey(e) {
    if(!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58) 
      || e.keyCode == 8)) {
        return false;
    }
}
}
