<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="m-auto m-4 col-md-10 col-lg-10 col-xs-12 col-sm-12">
                <form class="example-form animate__animated animate__zoomIn animate__delay-0.5s" #form="ngForm" (ngSubmit)="processForm(form)">
                    <h5 style="text-align:left;margin:0px;">LICENSE REQUEST</h5>
                    <hr>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Environment</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Select the environment you need new or additional license to be added"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field style="width: 300px;" appearance="outline">
                                <input #envNameInput [formControl]="envName" [matAutocomplete]="auto" required matInput
                                    [(ngModel)]="licenseDetail.EnvironmentName" (keyup)="filterEnv($event)">
                                <mat-autocomplete #auto="matAutocomplete"
                                    (optionSelected)="getPowerAppByEnvName($event)">
                                    <mat-option *ngFor="let environment of filteredEnvironment"
                                        [value]="environment.environmentName">
                                        {{environment.environmentName}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="envName.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Applications</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Applications and Solutions requiring new or additional licesne"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-form-field style="width: 300px;" appearance="outline">
                                <mat-chip-list #chipList>
                                    <mat-chip *ngFor="let item of selectedAppItems" [selectable]="true"
                                        >
                                        {{item}}
                                        <mat-icon *ngIf="item != 'NA'" (click)="removeApp(item)" matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                    <input [disabled]="isAppEnable" #appNameInput [formControl]="appName"
                                        [matAutocomplete]="autos" [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        [matChipInputAddOnBlur]="true" required (keyup)="filterApps($event)">
                                </mat-chip-list>
                                <mat-autocomplete #autos="matAutocomplete" (optionSelected)="addSelectedApp($event)">
                                    <mat-option *ngFor="let application of filteredApps"
                                        [value]="application.applicationName">
                                        {{application.applicationName}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-error class="radio-error" *ngIf="isSubmitted && selectedAppItems.length == 0">
                                {{getErrorMessage()}}</mat-error>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Number of license</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Enter the number of new or additional licenses require"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input min="0" (keypress)="isNumberKey($event)" type="number" matInput
                                    [formControl]="noOfLicense" [(ngModel)]="licenseDetail.LicenseNumber">
                                <mat-error *ngIf="noOfLicense.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Business Unit /
                                Function</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="The Business Unit / Function the Environment selected is associated with"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <!-- <mat-form-field class="example-full-width" appearance="outline">
                        <input type="text" readonly matInput placeholder="Which Power Platform product are you using?">
                    </mat-form-field> -->
                            <mat-form-field appearance="outline" style="width: 300px;">
                                <mat-select [(ngModel)]="licenseDetail.BusinessUnit" [formControl]="bussUnit">
                                    <mat-option *ngFor="let businessUnit of businessUnits"
                                        [value]="businessUnit.businessUnitName">
                                        {{businessUnit.businessUnitName}} ({{businessUnit.abbrivation}})
                                    </mat-option>
                                </mat-select>

                                <mat-error *ngIf="bussUnit.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Environment Owner</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Request will be send to the owner of the environment for review"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                                <mat-chip-list #chipListCon>
                                    <mat-chip *ngFor="let item of licenseDetail.EnvironmentOwner" [selectable]="true" [removable]="true">
                                        {{item.userName}}
                                    </mat-chip>
                                </mat-chip-list>

                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col text-center">
                            <button mat-raised-button color="dark"
                                style="width : 8em;;background-color: #333;color:#fff;"
                                (click)="location.back()">Back</button>&nbsp;&nbsp;&nbsp;
                            <button mat-raised-button color="warn" style="width : 8em;;">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  <ngx-spinner></ngx-spinner>
</section>