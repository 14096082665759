<section class="section-padding">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="mat-tab-container animate__animated animate__zoomIn animate__delay-0.5s">
          <mat-table [dataSource]="requestDataSourceMobile" matSort>

            <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

            <!-- Name Column -->
            <ng-container matColumnDef="environmentName">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                style="flex-direction: unset;justify-content: left;padding-left:15px;"> NAME </mat-header-cell>
              <mat-cell *matCellDef="let element" (click)="viewRequest(element)"> {{element.environmentName}}
              </mat-cell>
            </ng-container>

            <!-- Expires On Column -->
            <ng-container matColumnDef="existingEnvironment">
              <mat-header-cell *matHeaderCellDef class="ion-text-uppercase" mat-sort-header> REQUEST </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.existingEnvironment == 'No' ? 'Create' : 'Access'}}
              </mat-cell>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="reqstatus">
              <mat-header-cell *matHeaderCellDef class="ion-text-uppercase" mat-sort-header> STATUS </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.requestStatus}} </mat-cell>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef> ACTION </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="approveOrRejectRequest(element, 'Approved')">
                    <mat-icon>checkmark</mat-icon>
                    <span>Approve</span>
                  </button>
                  <button mat-menu-item (click)="approveOrRejectRequest(element, 'Rejected')">
                    <mat-icon>close</mat-icon>
                    <span>Reject</span>
                  </button>
                </mat-menu>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"></mat-row>
          </mat-table>
          <div class="norecord mt-4 mb-4" *ngIf="norecord">
            <mat-label>You don't have any pending request to approve</mat-label>
          </div>
          <div *ngIf="isFilterData" class="mt-4 mb-4">
            <mat-label>Your search <b>{{this.requestDataSourceMobile.filter}}</b> - did not match any Requests
            </mat-label>
          </div>
          <mat-paginator *ngIf="!norecord" #paginatorMobile [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"
            [showFirstLastButtons]="true">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</section>