<section class="section-padding">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="mat-tab-container animate__animated animate__zoomIn animate__delay-0.5s">
          <mat-table [dataSource]="requestDataSourceMobile" matSort>

            <!-- Name Column -->
            <ng-container matColumnDef="environmentName">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                style="flex-direction: unset;justify-content: left;padding-left:15px;"> NAME </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.environmentName}} </mat-cell>
            </ng-container>

            <!-- Expires On Column -->
            <ng-container matColumnDef="existingEnvironment">
              <mat-header-cell *matHeaderCellDef class="ion-text-uppercase" mat-sort-header> REQUEST </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.existingEnvironment == 'No' ? 'Create' : 'Access'}}
              </mat-cell>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="reqstatus">
              <mat-header-cell *matHeaderCellDef class="ion-text-uppercase" mat-sort-header> STATUS </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.requestStatus}} </mat-cell>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef> ACTION </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item [ngClass]="{'disabled':checkAccess('view', element.requestStatus) != true}">
                    <mat-icon>info</mat-icon>
                    <span>View</span>
                  </button>
                  <!-- <button mat-menu-item [ngClass]="{'disabled':checkAccess('delete', element.requestStatus) === true}">
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                  </button> -->
                </mat-menu>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"></mat-row>
          </mat-table>
          <div class="norecord mt-4" *ngIf="norecord">
            <mat-label class="message">You don't have any License Requests. Why not create one!</mat-label>
          </div>
          <div *ngIf="isFilterData" class="mt-4">
            <mat-label class="message">Your search <b>{{this.requestDataSourceMobile.filter}}</b> - did not match any
              Requests</mat-label>
          </div>
          <mat-paginator *ngIf="!norecord" #paginatorMobile [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"
            [showFirstLastButtons]="true">
          </mat-paginator>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <div class="norecord">
          <button mat-raised-button color="dark" style="width : 8em;;background-color: #333;color:#fff;"
            (click)="location.back()">Back</button>&nbsp;&nbsp;&nbsp;
          <button mat-raised-button routerLink="/services/license/createrequest" routerDirection="forward" color="warn"
            style="width : 15em;;">Create License Request</button>
        </div>
      </div>
    </div>
  </div>
</section>