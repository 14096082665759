<button mat-icon-button (click)="openSearch()">
  <mat-icon>search</mat-icon>
</button>
<mat-toolbar class="search-block mat-elevation-z4 main-header" [class.active]="toggleSearch">
  <mat-toolbar-row style="padding: 0 10px;">
    <button class="search-icon" mat-icon-button disabled>
      <mat-icon>search</mat-icon>
    </button>
    <input class="search-control" type="text" placeholder="Search" (keyup)="searchKey($event)" [(ngModel)]="searchText"
      #searchbar>
    <button mat-button mat-icon-button (click)="searchClose()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>