import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appFilter' })
export class FilterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @returns {any[]}
   */
  transform(items: any[], searchText: string, component: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();
    component = component.toLocaleLowerCase();
    console.log(component);
    return items.filter(it => {
      if(component === "/articles"){
        return it.title.rendered.toLocaleLowerCase().includes(searchText);
      }
      if(component === "" || component === "/" || component === "/home"){
        return it.title.rendered.toLocaleLowerCase().includes(searchText);
      }
      if(component === "/training"){        
        return it.subject.toLocaleLowerCase().includes(searchText);
      }
    });
  }
}
